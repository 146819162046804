.clientvisit-apartments {
  &__title {
    font-size: 38px;
    line-height: 1.1;
    text-transform: none;
    margin-bottom: 70px;
    margin-top: 170px !important;

    @extend %heading-font;

    @include media-breakpoint-only(sm) {
      margin-top: 90px !important;
      margin-bottom: 30px;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 70px !important;
      margin-bottom: 30px;
    }
  }

  &__apartment {
    background-color: #F9F7F5;
    padding-top: 32px;

    @include media-breakpoint-only(xs) {
      &:first-of-type {
        padding-top: 0;
      }
    }

    &:last-child {
      padding-bottom: 32px;

      @include media-breakpoint-only(xs) {
        padding-bottom: 60px;
      }
    }

    @include media-breakpoint-only(sm) {
      padding-bottom: 50px;

      &:last-child {
        padding-bottom: 80px;
      }
    }
  }
}

.clientvisit-apartment {
  position: relative;

  &:nth-child(odd) {
    .clientvisit-apartment__info {
      margin-left: auto;
    }

    .clientvisit-introduction__text {

      @include media-breakpoint-up(sm) {
        padding-left: 70px;
        padding-right: 0;
      }
    }

    .clientvisit-gallery {
      margin-left: 0;
    }
  }

  &__title {
    font-size: 72px;
    line-height: 1;
    text-transform: none;
    margin-top: 20px !important;
    margin-bottom: 20px !important;

    @extend %heading-font;

    @include media-breakpoint-only(xl) {
      margin-top: 20px !important;
      margin-bottom: 80px !important;
    }

    @include media-breakpoint-only(sm) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 23%;
      flex: 0 0 23%;
    }

    @include media-breakpoint-only(xs) {
      width: 40%;
      flex: 0 0 40%;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      text-align: center;
    }
  }

  &__features {
    list-style: none;
    margin: 0 0 50px 0;
    padding: 0;

    li {
      line-height: 2;

      @include media-breakpoint-down(sm) {
        line-height: 1.5;
      }

      &::before {
        content: '+ ';
      }
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-only(sm) {
      margin-bottom: 0;
      width: 43%;
      flex: 0 0 43%;
    }

    @include media-breakpoint-only(xs) {
      width: 60%;
      flex: 0 0 60%;
      margin-bottom: 0;
    }
  }

  .clientvisit-introduction__text {
    font-size: $font-size-base;

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: flex-start;
      margin-top: 16px;
      position: relative;
      padding-bottom: 40px;
    }

    @include media-breakpoint-only(xs) {
      flex-wrap: wrap;
      padding-bottom: 10px;
    }
  }

  &__text {
    @include media-breakpoint-only(sm) {
      width: 34%;
      flex: 0 0 34%;
    }

    @include media-breakpoint-only(xs) {
      width: 80%;
      flex: 0 0 80%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    border-radius: $border-radius;
    font-size: $font-size-xs;
    padding: 18px ($spacer * 3);
    letter-spacing: $letter-spacing;
    line-height: 1.09;
    text-transform: uppercase;
    font-weight: 600;

    @extend %text-font;

    &:focus {
      color: $body-color !important;
      box-shadow: none;
    }

    @include media-breakpoint-only(sm) {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    @include media-breakpoint-only(xs) {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
