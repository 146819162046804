.clientvisit-hero {
  position: relative;

  &__title {
    font-size: 64px;
    line-height: 1.12;
    text-transform: none;

    @extend %heading-font;

    @include media-breakpoint-only(sm) {
      font-size: 38px;
    }

    @include media-breakpoint-only(xs) {
      font-size: 26px;
      margin-top: auto !important;
      height: 120px;
      display: flex;
      align-items: flex-end;
    }
  }

  &__title,
  &__subtitle {
    text-transform: none;
  }

  &__wrap {
    @include media-breakpoint-only(xs) {
      margin-top: 0;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    height: 700px;

    @include media-breakpoint-only(sm) {
      height: 630px;
    }

    iframe,
    video {
      height: 100%;
      width: 177.77777778vh;
      min-width: 100%;
      min-height: 56.25vw;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @include media-breakpoint-only(xs) {
      display: none;
    }

    & + .page-header--with-background {
      @include media-breakpoint-up(sm) {
        background-image: none !important;
        z-index: 2;
      }
    }
  }

  &__content {
    p.text-white,
    p.clientvisit-hero__subtitle {
      @include media-breakpoint-down(sm) {
        color: $body-color !important;
        font-size: $font-size-base !important;
      }

      @include media-breakpoint-only(sm) {
        font-size: 24px !important;
      }
    }

    @include media-breakpoint-only(sm) {
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__subtitle {
    font-size: 24px !important;
    font-weight: normal !important;

    @extend %text-font;

    @include media-breakpoint-only(xs) {
      margin-top: 0 !important;
    }

    @include media-breakpoint-only(sm) {
      margin-top: 30px !important;
    }
  }

  &__arrow {
    background-color: $white;
    height: 50px;
    width: 1px;
    position: absolute;
    left: 10vw;
    animation: bounce 3s infinite;
    transition: $transition-base;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 3.5px 0 3.5px;
      border-color: $white transparent transparent transparent;
      position: absolute;
      top: 100%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-up(md) {
      bottom: 70px;
    }

    @include media-breakpoint-down(sm) {
      background-color: $black;
      top: calc(100% + 10vh);

      &::after {
        border-color: $black transparent transparent transparent;
      }
    }

    @include media-breakpoint-only(xs) {
      display: none;
    }
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
}
