.teaser-features {
  margin-bottom: 380px;

  @include media-breakpoint-only(sm) {
    margin-bottom: 70px;
  }

  @include media-breakpoint-only(xs) {
    margin-bottom: 80px;
  }

  &__image {
    &--square {
      width: 576px;
      display: block;
      margin-left: auto;
      position: relative;

      @include media-breakpoint-only(lg) {
        top: -50px; // for parallax
      }

      @include media-breakpoint-only(sm) {
        width: 427px;
        margin-bottom: 126px;
      }

      @include media-breakpoint-only(xs) {
        max-width: calc(100% + #{$grid-gutter-width-base * 2});
        margin-left: 0 - $grid-gutter-width-base;
        box-shadow: none;
        margin-bottom: 65px;
      }
    }

    &--portrait {
      @include media-breakpoint-up(sm) {
        position: absolute;
        z-index: -1;
        left: -50px;
        top: -160px;
        width: 640px;
      }

      @include media-breakpoint-only(md) {
        width: 450px;
        top: -100px;
      }

      @include media-breakpoint-only(sm) {
        width: 452px;
        left: -185px;
        top: 85px;
      }

      @include media-breakpoint-only(xs) {
        margin-bottom: 35px;
        padding-left: 22px;
        padding-right: 22px;
      }
    }
  }

  &__list {
    @include media-breakpoint-up(md) {
      padding-left: 60px;
    }

    @include media-breakpoint-only(xs) {
      padding-left: 22px;
      padding-right: 22px;
    }
  }

  &__button {
    margin-top: $spacer * 11;

    @include media-breakpoint-only(sm) {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: 75px;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 40px;
      margin-left: 20px;
    }
  }
}
