$body-bg: #fff;

// Fonts
$font-family-sans-serif: 'Proba Pro', sans-serif;
$font-size-base: 1rem;
$font-size-small: 0.875rem;

$small-font-size: 0.875rem;

$line-height-base: 1.75;

$lead-font-size:   1.125rem;
$lead-font-weight: 500;

// Colors
$brand-default:         #0055a6;
$brand-primary:         #4d4d4f;
$brand-secondary:       #d70a8c;
$brand-info:            #9c9fa2;
$brand-success:         #00a661;
$brand-warning:         #e5db49;
$brand-danger:          #e54949;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%);
$gray-dark:              lighten($gray-base, 20%);
$gray:                   #b2b4b6; // #555
$gray-light:             #f5f5f5;
$gray-lighter:           lighten($gray-base, 93.5%);

$link-color: $brand-secondary;
$text-color: $brand-primary;
$gray-light: #f5f5f5;
$gray-text:  $brand-info;
$text-muted: $gray-text;

// States
$state-warning-text: #bbb457;

// Headings
$font-size-h1: 38px;
$font-size-h2: 28px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 18px;

$headings-margin-bottom: 40px;
$headings-font-family:   $font-family-sans-serif;
$headings-font-weight:   300;
$headings-line-height:   1.2;
$headings-color:         $brand-primary;

$display1-size:          55px;

// Spacers
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: 10px,
    y: 10px
  ),
  2: (
    x: 20px,
    y: 20px
  ),
  3: (
    x: 30px,
    y: 30px
  ),
  4: (
    x: 40px,
    y: 40px
  ),
  5: (
    x: 100px,
    y: 100px
  )
);

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$badge-font-weight: normal;

// Inputs
$form-icon-success: none;
$form-icon-danger: none;
$form-icon-info: none;
$form-icon-warning: none;

// Borders
$border-color-light: #ececec;

$btn-border-radius:    0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

// Paddings
$padding-base-vertical:     8px;
$padding-base-horizontal:   20px;

$padding-large-vertical:    15px;
$padding-large-horizontal:  30px;

$padding-small-vertical:    5px;
$padding-small-horizontal:  15px;

$padding-xs-vertical:       5px;
$padding-xs-horizontal:     10px;


// Buttons
$btn-default-color:              #fff;
$btn-default-bg:                 $brand-default;
$btn-default-border:             $brand-default;

$btn-secondary-color:            #fff;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-border:           $brand-secondary;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $btn-info-bg;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $btn-danger-bg;

$btn-link-disabled-color:        $gray-light;

// Alerts colors
$alert-success-border: $brand-success;
$alert-success-text: $brand-success;
$alert-success-bg: rgba($brand-success, .08);

$alert-warning-border: $brand-warning;
$alert-warning-text: darken($brand-warning, 20%);
$alert-warning-bg: rgba($brand-warning, .08);

$alert-danger-border: $brand-danger;
$alert-danger-text: $brand-danger;
$alert-danger-bg: rgba($brand-danger, .08);

$alert-info-border: $brand-info;
$alert-info-text: $brand-info;
$alert-info-bg: rgba($brand-info, .08);

$alert-padding: 8px;
$alert-border-radius: 0;

// Forms
$input-bg:                       transparent;
$input-bg-disabled:              $gray-text;
$input-color:                    $text-color;
$input-border:                   $gray;
$input-border-radius:            0;
$input-border-radius-large:      0;
$input-border-radius-small:      0;
$input-border-focus:             $text-color;
$input-color-placeholder:        $gray-text;

$form-group-margin-bottom:       30px;

$input-height-base:              40px;

$legend-color:                   $gray-text;
$legend-border-color:            #c8cacf;

//
$modal-inner-padding:         10px 60px 60px 60px;

$modal-dialog-margin:         30px;
$modal-dialog-sm-up-margin-y: 60px;

$modal-title-line-height:     $line-height-base;

$modal-content-bg:               #fff;
$modal-content-border-color:     #b2b4b6;
$modal-content-border-width:     1px;
$modal-content-xs-box-shadow:    0 3px 9px rgba(#000000,.5);
$modal-content-sm-up-box-shadow: 0 5px 15px rgba(#000000,.5);

$modal-backdrop-bg:           #fff;
$modal-backdrop-opacity:      .72;
$modal-header-border-color:   transparent;
$modal-footer-border-color:   transparent;
$modal-header-border-width:   0;
$modal-footer-border-width:   0;
$modal-header-padding:        50px 60px 0 60px;

$modal-lg:                    600px;
$modal-md:                    600px;
$modal-sm:                    300px;

// Modal
/*
  $modal-title-padding:         50px 60px 0 60px;
  $modal-backdrop-bg:           #fff;
  $modal-backdrop-opacity:      .72;
  $modal-header-border-color:   transparent;
  $modal-footer-border-color:   $modal-header-border-color;
  $modal-title-line-height:     1.2;
*/

// Grid
$container-max-widths: (
  sm: 640px,
  md: 992px,
  lg: 1200px,
  xl: 1340px
);

$grid-gutter-width-base: 20px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);
$grid-breakpoints: (
  xs: 0,
  sm: 720px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px
);

// Custom controls
$custom-control-gutter: 2.25rem;
$custom-control-spacer-y: .5rem;
$custom-control-spacer-x: 2rem;

$custom-control-indicator-size: 1.625rem;
$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-bg-size: 16px;
$custom-control-indicator-box-shadow: inset 0 0 0 1px $text-color;

$custom-control-checked-indicator-color: $brand-default;
$custom-control-checked-indicator-bg: $input-bg;
$custom-control-checked-indicator-box-shadow: inset 0 0 0 1px $brand-default;

$custom-checkbox-checked-icon: url("../images/icon__16x16_check.svg");

$custom-control-focus-indicator-box-shadow: inset 0 0 0 1px $brand-default;

$custom-control-active-indicator-bg: $input-bg;
$custom-control-active-indicator-box-shadow: inset 0 0 0 1px $brand-default;

// Table
$table-head-bg: #fff;
$table-head-color: $text-color;
$table-bg-accent: $gray-light;
$table-border-width: 0;

$table-bg-hover: $gray-light;
$table-border-color: rgba(178, 180, 182, 0.3);

$table-cell-padding: 12px 15px;

$table-bg:                      #fff;

$table-inverse-bg:              $brand-default;
$table-inverse-color:           #fff;

// Figure
$figure-caption-color: $gray-text;

// Pagination
$pagination-padding-x:                0;
$pagination-padding-y:                14px;
$pagination-padding-x-sm:             14px;
$pagination-padding-y-sm:             0;
$pagination-padding-x-lg:             14px;
$pagination-padding-y-lg:             0;
$pagination-line-height:              1.25;

$pagination-color:                     $text-color;
$pagination-bg:                        $gray-light;
$pagination-border-width:              0;
$pagination-border-color:              transparent;

$pagination-hover-color:               $brand-default;
$pagination-hover-bg:                  #fff;
$pagination-hover-border:              $brand-default;

$pagination-active-color:              $text-color;
$pagination-active-bg:                 #fff;
$pagination-active-border:             $brand-default;

$pagination-disabled-color:            $gray-light;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;

// Lists
$list-inline-padding: 20px;

// Asides
$aside-width: 500px;
$aside-padding: 3.125rem;
$aside-transition: right 150ms ease-in-out;
$aside-bg-transition: all 150ms linear;
$aside-bg-color: $brand-default;
$aside-bg-opacity: 0.5;
$aside-z-index: 1030;

// Hamburgers
$hamburger-padding-x                       : 15px;
$hamburger-padding-y                       : 10px;
$hamburger-layer-width                     : 24px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 6px;
$hamburger-layer-color                     : #fff;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.1s;
$hamburger-hover-transition-timing-function: linear;

$hamburger-types: (
  squeeze
);

// Image thumbnails
$thumbnail-padding:           0;
$thumbnail-bg:                $gray-light;
$thumbnail-border-width:      10px;
$thumbnail-border-color:      #fff;
$thumbnail-border-radius:     0;
$thumbnail-box-shadow:        none;
$thumbnail-transition:        all .2s ease-in-out;

// Owl Carousel
$color-base:      $brand-default;
$color-white:     #FFF;
$color-gray:      $gray;

$dot-width:       8px;
$dot-height:      8px;
$dot-rounded:     50%;
$dot-margin:      0 10px;
$dot-background:  #fff;
$dot-background-active: $brand-default;

// Loader
$loader-size: 50px;
$loader-light-color: $gray-lighter;
$loader-dark-color: $brand-default;
$loader-weight: 3px;
$loader-speed: .7s;


// Cards
$card-spacer-x:            30px;
$card-spacer-y:            20px;
$card-border-width:        4px;
$card-border-radius:       0;
$card-border-color:        #f5f5f5;
$card-border-radius-inner: 0;
$card-bg:                  #f5f5f5;

// List group

$list-group-color:               $text-color;
$list-group-bg:                  #fff;
$list-group-border-color:        transparent;
$list-group-border-width:        0;
$list-group-border-radius:       0;

$list-group-item-padding-x:      40px;
$list-group-item-padding-y:      30px;

$list-group-hover-bg:            $brand-default;
$list-group-active-color:        #fff;
$list-group-active-bg:           $brand-default;
$list-group-active-border:       transparent;
$list-group-active-text-color:   #fff;
//
//$list-group-disabled-color:      $gray-light !default;
//$list-group-disabled-bg:         $list-group-bg !default;
//$list-group-disabled-text-color: $list-group-disabled-color !default;
//
$list-group-link-color:          $text-color;
//$list-group-link-heading-color:  $gray-dark !default;
$list-group-link-hover-color:    #fff;
//
//$list-group-link-active-color:   $list-group-color !default;
//$list-group-link-active-bg:      $gray-lighter !default;//
