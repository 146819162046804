@import "../../bower_components/bootstrap/scss/_variables.scss";
@import "../../bower_components/bootstrap/scss/_mixins.scss";
@import 'common/variables';
@import 'client-visit/fonts';

$dark: #222232;
$dark-blueish: #001736;
$dark-lighter: #444460;
$peach: #db9778;
$border-radius: 5px;
$font-size-xs: 11px;
$font-size-xxs: 10px;
$spacer: 8px;
$letter-spacing: 4px;

$transition-cubic: cubic-bezier(0.2, 0.6, 0, 1);
$transition-base: all 0.4s $transition-cubic;

html {
  scroll-behavior: smooth;
}

.page-template-template-teaser,
.page-template-template-client-visit-day {
  @extend %text-font;

  .hamburger {
    background-color: transparent !important;
  }
}

.page-header {
  &--with-background {
    height: 700px;

    @include media-breakpoint-only(sm) {
      height: 630px;
    }

    @include media-breakpoint-only(xs) {
      height: 320px;
    }
  }

  &__content {
    padding-top: 150px;
    padding-bottom: 60px;
    height: 100%;

    @include media-breakpoint-only(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include media-breakpoint-only(xs) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.contacts {
  &__menu {
    &-text {
      font-size: $font-size-xxs;
      letter-spacing: $letter-spacing;
      font-weight: 600;
      margin-right: 5px !important;

      @extend %text-font;
    }
  }
}

.ninja-forms-form-wrap {
  .nf-form-content {
    .nf-field-container {
      margin-bottom: $spacer;
    }
  }

  select.form-control {
    background-image: url("../images/arrow-down.svg");
    background-position: 100% 50%;

    @include hover-focus {
      background-image: url("../images/arrow-down.svg");
    }
  }

  .ninja-forms-req-symbol {
    &.text-danger {
      color: $body-color !important;
    }
  }

  .checkbox-wrap {
    position: relative;
    display: block;

    input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;
    }

    label {
      position: relative;
      padding-left: 45px;
      top: 0;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.36;

      &::before {
        position: absolute;
        top: .25rem;
        left: 0;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #2d2d2d solid 2px;
      }

      &.nf-checked-label {
        &::after {
          background: transparent url("../images/checked.svg") no-repeat center center;
          content: "";
          position: absolute;
          width: 1rem;
          height: 1rem;
          left: 0;
          top: calc(0.25rem - 2px);
        }
      }
    }
  }

  .btn-default {
    background-color: $dark-lighter !important;
    border: 1px solid $dark-lighter;
    margin-top: 40px;
    border-radius: $border-radius;
    font-size: $font-size-xs;
    padding: 18px ($spacer * 3);
    letter-spacing: $letter-spacing;
    line-height: 1.09;
    text-transform: uppercase;
    font-weight: 600;

    @extend %text-font;

    @include hover-focus-active {
      border-color: $dark-lighter !important;
      background-color: transparent !important;
      cursor: pointer;
      color: $dark-lighter !important;
    }
  }

  textarea.form-control {
    height: calc(#{$input-height} + 2px);
  }

  .nf-response-msg {
    h3 {
      font-size: 38px;
      line-height: 1.1;
      margin-top: 0;
      margin-bottom: 40px;

      @extend %heading-font;
    }

    p {
      & + p {
        margin-top: 40px;
      }
    }
  }
}

@import 'client-visit/common';
@import 'client-visit/hero';
@import 'client-visit/introduction';
@import 'client-visit/apartments';
@import 'client-visit/gallery';
@import 'client-visit/contact';
