.owl-carousel {
  .owl-nav {
    .owl-next,
    .owl-prev {
      display: block;

      @include media-breakpoint-only(xs) {
        margin-left: 0;
        margin-right: 0;
        width: 40px;
        height: 40px;
        margin-top: -20px;
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: $spacer * 4;
    z-index: 20;
    left: 0;
    right: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-only(sm) {
      bottom: $spacer * 6;
    }

    @include media-breakpoint-only(xs) {
      bottom: $spacer * 2;
    }

    .owl-dot {
      pointer-events: auto;
      cursor: pointer;
      display: block;
      padding: $spacer 0;

      span {
        width: 10px;
        height: 10px;
        border: 1px solid $white;
        background-color: transparent;
        transition: $transition-base;
      }

      @include hover-focus-active {
        span {
          background-color: rgba($white, 0.5);
        }
      }

      &.active {
        span {
          background-color: $white;
        }
      }
    }
  }
}
