.teaser-introduction {
  margin-bottom: 400px;

  @include media-breakpoint-up(lg) {
    .clientvisit-introduction__title {
      margin-bottom: 150px;
    }
  }

  @include media-breakpoint-only(sm) {
    margin-bottom: 185px;
  }

  @include media-breakpoint-only(xs) {
    margin-bottom: 35px;
    padding-left: 22px;
    padding-right: 22px;
  }

  &__list {
    margin-bottom: 0;

    li {
      font-size: $font-size-h4;
      line-height: 1.4;
      padding-left: $spacer * 5;
      position: relative;

      @include media-breakpoint-only(xs) {
        font-size: $font-size-base;
        padding-left: $spacer * 3;
      }

      &::before {
        content: "~";
        position: absolute;
        left: 0;
        top: 0;
      }

      & + li {
        margin-top: $spacer * 2;

        @include media-breakpoint-only(xs) {
          margin-top: $spacer;
        }
      }
    }
  }

  &__image {
    &--portrait {
      right: -160px;
      top: 100px;
      width: 512px;

      @include media-breakpoint-up(sm) {
        position: absolute;
        z-index: -1;
      }

      @include media-breakpoint-only(md) {
        width: 400px;
        top: 80px;
      }

      @include media-breakpoint-only(sm) {
        width: 452px;
        right: -185px;
        top: 0;
      }

      @include media-breakpoint-only(xs) {
        margin-top: 60px;
        top: auto;
        right: auto;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &--square {
      position: relative;

     /*@include media-breakpoint-only(xl) {
        top: -100px; // for parallax
      }

      @include media-breakpoint-only(lg) {
        top: -130px; // for parallax
      }*/

      @include media-breakpoint-only(sm) {
        margin-top: 95px;
        width: 427px;
      }

      @include media-breakpoint-only(xs) {
        margin-top: 35px;
        max-width: calc(100% + 64px);
        margin-left: -32px;
        box-shadow: none;
      }
    }
  }

  .clientvisit-introduction__title {
    @include media-breakpoint-only(xs) {
      margin-top: 16px !important;
      margin-bottom: 70px;
      line-height: 1.15;
      padding-right: $spacer * 2;
    }
  }
}
