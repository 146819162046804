.clientvisit-contact {
  padding-top: 90px;
  padding-bottom: 140px;

  @include media-breakpoint-only(xs) {
    padding-top: 50px;
    padding-bottom: 90px;
  }

  &__title {
    font-size: 24px;
    line-height: 1.33;

    @include media-breakpoint-only(xs) {
      font-size: $font-size-base;
    }
  }

  &__text {
    @include media-breakpoint-only(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .clientvisit-hero__button {
    @include hover-focus-active {
      background-color: darken($dark, 10%) !important;
      color: $white !important;
    }

    @include media-breakpoint-only(xs) {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}

.clientvisit-card {
  @include media-breakpoint-only(xs) {
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &--right {
    text-align: right;

    @include media-breakpoint-only(xs) {
      text-align: left;
    }

    img {
      order: 2;
      margin-right: 0;
      margin-left: 60px;

      @include media-breakpoint-only(xs) {
        order: 1;
        margin-left: 0;
        max-width: 130px;
      }
    }

    .contact-card__info {
      @include media-breakpoint-only(xs) {
        order: 2;
        padding-left: 20px;
      }
    }
  }
}

.contact-card {
  &__image {
    max-width: 145px;
    margin-right: 60px;
  }

  a {
    color: $body-color;
    text-decoration: underline;
  }
}
