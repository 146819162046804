.teaser-hero {
  @include media-breakpoint-only(xs) {
    min-height: 568px;
  }

  .clientvisit-hero__content {
    p.clientvisit-hero__subtitle {
      @include media-breakpoint-only(xs) {
        margin-bottom: $spacer * 2 !important;
        font-size: 16px !important;
      }
    }
  }

  .clientvisit-hero__wrap {
    @include media-breakpoint-only(xs) {
      padding: 20px 32px !important;
    }
  }

  .clientvisit-hero__button {
    @include media-breakpoint-only(xs) {
      font-size: 9px;
      padding: 14px 16px;
      width: auto;
    }
  }

  .clientvisit-hero__video {
    @include media-breakpoint-only(xs) {
      display: block;
      height: 320px;

      &::before {
        padding-top: 100%;
        display: block;
        content: "";
      }

      &::after {
        background: transparent url("../images/teaser-play-video.svg") no-repeat center center;
        content: "";
        position: absolute;
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        top: 50%;
        margin-top: -30px;
      }

      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        border: 0;
        width: 161vw;
        left: -30.5vw;
        min-width: 0;
        transform: none;
        display: none;
      }

      &--visible {
        iframe {
          display: block;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
