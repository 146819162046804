@font-face {
  font-family: 'Wulkan';
  src: url('../fonts/wulkan_display_regular-webfont.woff2') format('woff2'),
       url('../fonts/wulkan_display_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Ideal Sans';
  src: url('../fonts/IdealSans-Semibold.eot');
  src: url('../fonts/IdealSans-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/IdealSans-Semibold.woff2') format('woff2'),
      url('../fonts/IdealSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ideal Sans Book';
  src: url('../fonts/IdealSans-BookItalic.eot');
  src: url('../fonts/IdealSans-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/IdealSans-BookItalic.woff2') format('woff2'),
      url('../fonts/IdealSans-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ideal Sans';
  src: url('../fonts/IdealSans-Light.eot');
  src: url('../fonts/IdealSans-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/IdealSans-Light.woff2') format('woff2'),
      url('../fonts/IdealSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ideal Sans Book';
  src: url('../fonts/IdealSans-Book.eot');
  src: url('../fonts/IdealSans-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/IdealSans-Book.woff2') format('woff2'),
      url('../fonts/IdealSans-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ideal Sans';
  src: url('../fonts/IdealSans-SemiboldItalic.eot');
  src: url('../fonts/IdealSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/IdealSans-SemiboldItalic.woff2') format('woff2'),
      url('../fonts/IdealSans-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ideal Sans';
  src: url('../fonts/IdealSans-LightItalic.eot');
  src: url('../fonts/IdealSans-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/IdealSans-LightItalic.woff2') format('woff2'),
      url('../fonts/IdealSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'Kudryashev Display';
    src: url('../fonts/KudryashevDisplay.eot');
    src: url('../fonts/KudryashevDisplay.eot?#iefix') format('embedded-opentype'),
        url('../fonts/KudryashevDisplay.woff2') format('woff2'),
        url('../fonts/KudryashevDisplay.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

%heading-font {
  font-family: 'Wulkan';

  .lang-ru & {
    font-family: 'Kudryashev Display';
  }
}

%text-font {
  body:not(.lang-ru) & {
    font-family: 'Ideal Sans';
  }
}

@mixin text-font {
  body:not(.lang-ru) & {
    font-family: 'Ideal Sans';
  }
}
