.teaser-contact {
  padding-bottom: 90px;

  @include media-breakpoint-only(sm) {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  @include media-breakpoint-only(xs) {
    padding-top: 65px;
    padding-bottom: 35px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }

  .contact-card {
    @extend %text-font;

    @include media-breakpoint-only(xs) {
      width: 100%;
    }

    &__info {
      @include media-breakpoint-only(xs) {
        font-size: 13px;
      }
    }

    &__name {
      @include media-breakpoint-up(md) {
        font-size: $font-size-h3;
      }

      @include media-breakpoint-only(xs) {
        font-size: 16px;
        margin-bottom: $spacer * 4 !important;
      }
    }

    &__person {
      font-weight: normal !important;
    }

    &__image {
      @include media-breakpoint-only(sm) {
        max-width: 128px;
        margin-left: $spacer * 3;
      }

      @include media-breakpoint-only(xs) {
        max-width: 115px;
      }
    }
  }

  .clientvisit-contact {
    &__text {
      @include media-breakpoint-only(sm) {
        max-width: 240px;
      }
    }

    &__title {
      @include media-breakpoint-up(md) {
        font-size: $font-size-h1;
      }

      @include media-breakpoint-only(sm) {
        margin-bottom: $spacer * 2 !important;
        font-size: 26px;
      }

      @include media-breakpoint-only(xs) {
        padding-right: 0;
        margin-bottom: $spacer * 3;
      }
    }
  }

  .clientvisit-card {
    @include media-breakpoint-only(xs) {
      margin-top: 70px;
    }

    img {
      @include media-breakpoint-only(xs) {
        order: 2;
        margin-left: auto;
      }
    }

    a {
      text-decoration: none;
    }

    .contact-card__info {
      @include media-breakpoint-only(xs) {
        order: 1;
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }

  .clientvisit-hero {
    &__button {
      @include media-breakpoint-only(xs) {
        width: auto;
        padding: 14px 16px;
        font-size: 9px;
      }
    }
  }
}
