.teaser-video {
  background-color: $white;

  &__list {
    padding-left: 20px;

    @include media-breakpoint-only(sm) {
      padding-left: 0;
    }
  }

  &__button {
    margin-left: 60px;
    margin-top: 90px;
    margin-bottom: 180px;

    @include media-breakpoint-only(sm) {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: 60px;
      margin-left: 0;
      margin-bottom: 140px;
    }

    @include media-breakpoint-only(xs) {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: 60px;
      margin-left: 22px;
      margin-bottom: 80px;
    }
  }

  &__image {
    margin-left: 35px;
    width: 448px;

    &--portrait {
      position: relative;
    }

    @include media-breakpoint-only(sm) {
      width: 100%;
      margin-left: 0;
      margin-top: -145px;
    }

    @include media-breakpoint-only(xs) {
      margin-left: 0;
      width: 100%;
      margin-bottom: 70px;
    }
  }

  &__top {
    background: #ecf1f2 url("../images/teaser-video-background-min.jpg") no-repeat bottom -220px center;
    background-size: cover;

    @include media-breakpoint-only(md) {
      background-position: bottom -150px center;
    }

    @include media-breakpoint-only(xs) {
      background-size: 200% auto;
      background-position: bottom -30px center;
    }
  }

  &__video {
    margin-bottom: 580px;

    @include media-breakpoint-only(md) {
      margin-bottom: 470px;
    }

    @include media-breakpoint-only(sm) {
      margin-bottom: 290px;
    }

    @include media-breakpoint-only(xs) {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      margin-bottom: 0;
      display: none;

      &--visible {
        z-index: 2;
        display: block;
      }

      &:before {
        padding-top: 100%;
      }

      .embed-responsive-item {
        width: 161vw;
        left: -30.5vw;
      }
    }

    video,
    iframe {
      width: 100%;
    }
  }

  &__placeholder {
    @include media-breakpoint-only(xs) {
      margin-bottom: 220px;
      margin-left: - $grid-gutter-width-base / 2;
      width: calc(100% + #{$grid-gutter-width-base});
      position: relative;

      &::after {
        background: transparent url("../images/teaser-play-video.svg") no-repeat center center;
        content: "";
        position: absolute;
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        top: 50%;
        margin-top: -30px;
      }
    }

    @include media-breakpoint-up(sm) {
      img {
        display: none;
      }
    }
  }

  &__col-image {
    @include media-breakpoint-only(xs) {
      order: 1;
      padding-left: $spacer * 4;
      padding-right: $spacer * 4;
    }
  }

  &__col-list {
    @include media-breakpoint-only(xs) {
      order: 2;
    }
  }

  &__title {
    font-size: 64px;
    margin-bottom: 150px;
    margin-top: 150px !important;

    @include media-breakpoint-only(sm) {
      max-width: 100%;
      margin-top: 80px !important;
      margin-bottom: 100px;
      font-size: $font-size-h1;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 85px !important;
      margin-bottom: 80px !important;
      font-size: 26px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 1.15;
    }
  }

  &__bottom {
    margin-top: -300px;

    @include media-breakpoint-only(md) {
      margin-top: -220px;
    }

    @include media-breakpoint-only(sm) {
      margin-top: 80px;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 0 - 60px;
    }
  }
}
