.clientvisit-hero {
  &__button {
    background-color: $dark;
    border-radius: $border-radius;
    border: 0;
    font-size: $font-size-xs;
    padding: 18px ($spacer * 3);
    letter-spacing: $letter-spacing;
    line-height: 1.09;
    text-transform: uppercase;
    font-weight: 600;

    @extend %text-font;

    &--calendar {
      display: inline-flex;
      align-items: center;

      @include hover-focus-active {
        background-color: darken($dark, 10%) !important;
        color: $white !important;
      }

      &::after {
        background: transparent url("../images/calendar.svg") no-repeat center center;
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        margin-left: $spacer * 3;
      }
    }
  }
}
