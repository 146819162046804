.teaser-footer {
  padding-top: $spacer * 8;
  padding-bottom: $spacer * 6;

  @extend %text-font;

  @include media-breakpoint-only(sm) {
    padding-bottom: $spacer * 15;
  }

  @include media-breakpoint-only(xs) {
    padding: $spacer * 4 22px $spacer * 12;
  }

  &__title {
    text-transform: none;
    margin: 0 !important;

    @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 1.54;
      margin-bottom: 24px !important;
    }
  }

  &__social-media {
    list-style-type: none;
    margin: $spacer * 10 0 0 0;
    padding: 0;
    display: flex;
    align-items: center;

    li {
      margin-right: $spacer * 2;

      &:first-child {
        margin-right: $spacer * 6;

        @include media-breakpoint-only(xs) {
          margin-right: $spacer * 4;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 0;
      position: absolute;
      top: 234px;
    }

    @include media-breakpoint-only(xs) {
      top: 150px;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
    }
  }

  &__social-item {
    display: block;
    transition: $transition-base;
    opacity: 1;

    @include hover-focus-active {
      opacity: 0.6;
    }

    &::before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
    }

    &--facebook {
      &::before {
        background: transparent url("../images/teaser-facebook.svg") no-repeat center center;
      }
    }

    &--instagram {
      &::before {
        background: transparent url("../images/teaser-instagram.svg") no-repeat center center;
      }
    }
  }

  &__newsletter {
    max-width: 320px;
    border-radius: 5px;
    margin-left: auto;
    margin-top: $spacer;
    position: relative;

    @include media-breakpoint-only(sm) {
      max-width: 100%;
      margin-top: $spacer * 8;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 0;
      max-width: 100%;
    }

    .form-control {
      background-color: #F9F7F5;
      border-radius: 5px 0 0 5px;
      padding-left: $spacer * 3;
      z-index: 1;
      position: relative;
      border-color: #f9f7f5;
      font-style: italic;

      @extend %text-font;

      &::placeholder {
        color: #909090;
      }
    }

    .input-group {
      background-color: #F9F7F5;
      border-radius: 5px;
      overflow: hidden;
    }

    .btn {
      z-index: 2;
      position: relative;
      text-align: center;

      @include media-breakpoint-only(xs) {
        margin-top: 0;
      }
    }

    .valid-feedback {
      font-size: $font-size-h3;
      font-weight: 300;
      line-height: 1.3;
      margin-top: - $spacer;

      @extend %text-font;

      @include media-breakpoint-down(sm) {
        font-size: 16px;;
      }
    }

    .invalid-feedback {
      position: absolute;
      top: calc(100% + #{$spacer});
      left: 0;
      right: 0;
      transform: translateY(-8px);
      opacity: 0;
      font-size: $font-size-sm;
      transition: $transition-base;

      &--visible {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
