@import 'client-visit';

.teaser-image-shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.24);

  @include media-breakpoint-only(xs) {
    transform: none !important;
  }
}

.teaser-booking-button {
  position: fixed;
  right: $spacer * 8;
  bottom: $spacer * 8;
  z-index: 10;
  opacity: 1;

  &--hidden {
    @include media-breakpoint-up(sm) {
      opacity: 0;
      transform: translateY($spacer);
      pointer-events: none;
    }

    @include media-breakpoint-only(xs) {
      &.teaser-booking-button--end {
        opacity: 0;
        transform: translateY($spacer);
        pointer-events: none;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    width: auto;
    right: $spacer * 2;
    font-size: 9px;
    padding: 14px 16px;
  }
}

.clientvisit-apartment__button {
  @include media-breakpoint-only(xs) {
    font-size: 9px;
    padding: 14px 16px;
    width: auto;
    letter-spacing: 3px;
  }
}

.btn-peach {
  @include button-variant($white, $peach, $peach);
}

body.front-page {
  background-color: #f9f7f5;

  &.aside-menu-opened {
    background-color: $white;
  }
}

.row--wide {
  margin-left: - ($spacer * 4 / 2);
  margin-right: - ($spacer * 4 / 2);

  [class^="col"] {
    padding-left: $spacer * 4 / 2;
    padding-right: $spacer * 4 / 2;
  }
}

.mb-spacer-4 {
  margin-bottom: $spacer * 4 !important;

  @include media-breakpoint-only(xs) {
    margin-bottom: $spacer * 2 !important;
  }
}

@keyframes dropdownSlideIn {
  from {
    opacity: 0;
    transform: translateY(- #{$spacer});
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn {
  &__mobile-text {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

@import 'teaser/common';
@import 'teaser/hero';
@import 'teaser/apartments';
@import 'teaser/features';
@import 'teaser/introduction';
@import 'teaser/video';
@import 'teaser/additional';
@import 'teaser/interior';
@import 'teaser/contact';
@import 'teaser/footer';
@import 'teaser/carousel';
