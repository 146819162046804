.teaser-apartments {
  position: relative;

  &__top {
    background-color: #efece5;
    padding: $spacer * 4 0 $spacer * 4 0;

    @include media-breakpoint-only(xs) {
      padding-bottom: $spacer * 2;
    }
  }

  &__title {
    font-size: $font-size-h3;
    margin: 0 !important;
    text-transform: none;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin-bottom: $spacer * 3 !important;
    }

    @include media-breakpoint-only(xs) {
      text-align: center;
      font-size: 16px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-only(md) {
      justify-content: flex-start;
    }

    @include media-breakpoint-only(xs) {
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__filter {
    @extend %text-font;

    @include media-breakpoint-only(xs) {
      &:not(.teaser-apartments__filter--terrace):not(.teaser-apartments__filter--clear):not(.teaser-apartments__filter--sauna) {
        flex-grow: 1;

        select {
          width: 100%;
          min-width: 50px;
        }
      }
    }

    label:not(.custom-control) {
      display: block;
      margin-bottom: $spacer;
      opacity: 0.6;
      padding-left: $spacer * 2;
      font-size: $font-size-sm;

      @include media-breakpoint-only(xs) {
        font-size: 11px;
        padding-left: 0;
        text-align: center;
      }
    }

    select {
      background: $white url("../images/teaser-filter-arrow-down.svg") no-repeat right $spacer center;
      border-radius: $border-radius;
      border-width: 0;
      min-width: 85px;
      padding-left: $spacer * 2;
      padding-right: $spacer * 5;
      font-weight: 300;

      @extend %text-font;

      @include media-breakpoint-only(xs) {
        font-size: 12.8px;
        padding-left: 12px;
        padding-right: $spacer * 4;
        background-size: 16px;
      }
    }

    .custom-control {
      height: 20px;
      width: 20px;
      padding: 0;
      margin: 0 auto;
      display: block;
    }

    .custom-control-input {
      &:checked {
        & ~ .custom-control-indicator {
          box-shadow: none;
          background: $white url("../images/icon__20x20_check.svg") no-repeat center center;
        }
      }

      &:focus,
      &:active {
        & ~ .custom-control-indicator {
          box-shadow: none;
        }
      }
    }

    .custom-control-indicator {
      background-color: $white;
      box-shadow: none;
      width: 20px;
      height: 20px;
    }

    & + & {
      margin-left: $spacer * 4;

      @include media-breakpoint-only(xs) {
        margin-left: $spacer;
      }
    }

    & + .teaser-apartments__filter--terrace,
    & + .teaser-apartments__filter--clear {
      margin-left: $spacer * 4;
    }

    &--terrace {
      @include media-breakpoint-only(xs) {
        flex: 0 0 30%;
      }
    }

    &--sauna,
    &--terrace {
      @include media-breakpoint-only(xs) {
        display: flex;
        align-items: center;
        margin-top: $spacer * 2;
        margin-left: $spacer * 3 !important;

        label:not(.custom-control) {
          margin-bottom: 0;
          margin-right: $spacer * 2;
        }
      }
    }
  }

  &__clear {
    cursor: pointer;
    display: block;
    margin-top: 24px;
    opacity: 1;
    transition: $transition-base;
    cursor: pointer;
    position: relative;

    @include media-breakpoint-only(xs) {
      margin-top: 0;
      top: 6px;
    }

    &--hidden {
      pointer-events: none;
      opacity: 0.4;
    }

    @include hover-focus-active {
      &:not(.teaser-apartments__clear--hidden) {
        opacity: 0.6;
      }
    }

    &::before {
      background: transparent url("../images/icon__20x20_close.svg") no-repeat center center;
      display: block;
      content: "";
      width: 40px;
      height: 40px;
    }
  }

  &__apartments {
    margin-top: $spacer * 8;
    margin-bottom: $spacer * 8;

    @include media-breakpoint-only(xs) {
      margin-top: 0;
      margin-bottom: $spacer * 4;
    }
  }

  &__load-more {
    border-width: 0;
    margin-top: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: $spacer * 2;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 0;
    }

    &::after {
      content: "";
      display: block;
      background: transparent url("../images/teaser-filter-arrow-down.svg") no-repeat center center;
      width: 16px;
      height: 16px;
      margin-top: $spacer * 2;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    @include hover-focus-active {
      color: $black;
      background-color: transparent;

      &:not(.teaser-apartments__load-more--disabled) {
        transform: translateY($spacer);
      }
    }
  }
}

.teaser-apartment {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: dropdownSlideIn;
  position: relative;

  @include media-breakpoint-only(xs) {
    margin-left: 0 - $grid-gutter-width-base / 2;
    margin-right: 0 - $grid-gutter-width-base / 2;
  }

  &--hidden {
    display: none;
  }

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;

    @include hover-focus-active {
      .teaser-apartment__info {
        background-color: rgba($dark, 1);
      }

      .teaser-apartment__link {
        opacity: 1;
      }
    }
  }

  &__info {
    background-color: rgba($dark-blueish, 0.6);
    margin-top: auto;
    transition: $transition-base;
    color: $white;
    padding: $spacer $spacer * 4;

    @include media-breakpoint-up(sm) {
      min-height: $spacer * 8;
    }

    @include media-breakpoint-only(xs) {
      padding: $spacer $spacer * 2;
    }
  }

  &__number {
    color: $white;
    margin: -10px 0 0 0 !important;
    font-weight: 300;

    @extend %text-font;

    @include media-breakpoint-only(xs) {
      font-size: 16px;
      margin: 0 !important;
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-left: auto;
    font-size: $font-size-sm;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
      .teaser-apartment--small & {
        max-width: 85%;
        flex: 0 0 85%;

        .teaser-apartment__size-value {
          margin-right: 0;
        }

        .teaser-apartment__price-label {
          width: 50%;
          text-align: right;

          &::before {
            display: none;
          }
        }
      }
    }

    @include media-breakpoint-only(xs) {
      font-size: 11px;
    }

    @extend %text-font;

    dd {
      margin-bottom: 0;
    }

    dt {
      margin-right: $spacer / 2;

      &:not(:first-of-type) {
        &::before {
          content: "|";
          margin-right: $spacer;

          @include media-breakpoint-only(xs) {
            margin-right: $spacer / 2;
          }
        }
      }
    }

    dd {
      &:not(:last-of-type) {
        margin-right: $spacer;

        @include media-breakpoint-only(xs) {
          margin-right: $spacer / 2;
        }
      }
    }

    dt,
    dd {
      font-weight: 300;
    }
  }

  &__link {
    background-color: rgba($dark-blueish, 0.6);
    color: $white;
    text-transform: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    display: flex;
    align-items: center;
    font-weight: 300;
    letter-spacing: 0;
    font-size: $font-size-sm;

    @include media-breakpoint-down(sm) {
      right: $spacer * 2;
      bottom: $spacer * 10;
      top: auto;
      left: auto;
      opacity: 1;
      background-color: rgba($dark-blueish, 1);
      transform: none;
    }

    &::after {
      content: "";
      width: 16px;
      height: 16px;
      background: transparent url("../images/teaser-arrow-right.svg") no-repeat center center;
      margin-left: $spacer * 2;
    }

    @include media-breakpoint-only(xs) {
      padding: $spacer $spacer * 2;
      bottom: $spacer * 6;

      &::after {
        background-size: $spacer $spacer;
        margin-left: $spacer;
      }
    }

    @include hover-focus-active {
      background-color: rgba($dark, 1);
      color: $white;
    }
  }
}
