.teaser-interior {
  padding-top: $spacer * 20;
  padding-bottom: $spacer * 20;

  @include media-breakpoint-only(sm) {
    padding-top: $spacer * 12;
    padding-bottom: $spacer * 13;
  }

  @include media-breakpoint-only(xs) {
    padding-bottom: 100px;
    padding-top: 100px;
    padding-left: 22px;
    padding-right: 22px;
  }

  &__button {
    margin-top: $spacer * 15;

    @include media-breakpoint-down(sm) {
      margin-top: $spacer * 9;
      position: relative;
      right: auto;
      bottom: auto;
    }

    @include media-breakpoint-only(xs) {
      margin-top: $spacer * 8;
    }
  }
}
