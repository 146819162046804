.teaser-additional {
  background-color: $white;
  padding-top: 145px;
  padding-bottom: 145px;

  @include media-breakpoint-only(sm) {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  @include media-breakpoint-only(xs) {
    padding-top: 65px;
    padding-bottom: 100px;
    padding-left: 22px;
    padding-right: 22px;
  }

  &__title {
    margin-top: 0 !important;
    line-height: 1.1;
    text-transform: none;
    color: $dark;

    @extend %heading-font;

    @include media-breakpoint-only(sm) {
      margin-bottom: 70px;
    }

    @include media-breakpoint-only(xs) {
      font-size: 26px;
      padding-right: 20px;
      line-height: 1.15;
    }
  }

  &__description {
    font-size: $font-size-h5;
    line-height: 1.7;

    @include media-breakpoint-only(xs) {
      font-size: $font-size-base;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
