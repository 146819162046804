.clientvisit-gallery {

  @include media-breakpoint-up(md) {
    margin-left: auto;
    width: 55vw;
  }

  @include media-breakpoint-only(sm) {
    padding-left: $grid-gutter-width-base;
    padding-right: $grid-gutter-width-base;
  }
}

.clientvisit-carousel {
  .owl-dots {
    display: none;
  }

  .owl-nav {
    margin-top: 0;

    .owl-prev {
      display: none;
    }

    .owl-next,
    .owl-prev {
      background: $dark url("../images/arrow-next.svg") no-repeat center center;
      margin: 0;
      width: 50px;
      height: 50px;
      border-radius: $border-radius;
      display: block;
      padding: 0;
      margin-left: auto;
      margin-top: -25px;

      @include media-breakpoint-only(xs) {
        width: 30px;
        height: 30px;
        margin-top: -15px;
      }

      @include hover-focus-active {
        background: darken($dark, 10%) url("../images/arrow-next.svg") no-repeat center center;
      }
    }

    .owl-next {
      margin-right: 60px;

      @include media-breakpoint-down(md) {
        margin-right: 30px;
      }
    }

    .owl-prev {
      transform: rotate(180deg);
      margin-left: 60px;

      @include media-breakpoint-down(md) {
        margin-left: 30px;
      }
    }
  }
}
