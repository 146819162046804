.clientvisit-introduction {
  @include media-breakpoint-only(xs) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__title {
    font-size: 72px;
    line-height: 1;
    text-transform: none;
    letter-spacing: -0.8px;
    margin-top: 130px !important;
    margin-bottom: 100px;

    @extend %heading-font;

    @include media-breakpoint-only(sm) {
      font-size: 38px;
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 65px;
      margin-top: 65px !important;
    }

    @include media-breakpoint-only(xs) {
      font-size: 26px;
      margin-bottom: 30px;
      margin-top: 80px !important;
    }
  }

  &__image {
    margin-bottom: 90px;

    @include media-breakpoint-only(sm) {
      margin-left: 50%;
      left: -46vw;
      width: 92vw;
      max-width: 92vw;
      position: relative;
    }

    @include media-breakpoint-only(xs) {
      margin-bottom: 30px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.7778;

    @extend %text-font;

    @include media-breakpoint-up(md) {
      padding-right: 30px;
    }

    @include media-breakpoint-only(sm) {
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 1.5;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__form {
    .nf-form-title {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 30px;
    }

    @include media-breakpoint-only(sm) {
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
    }

    @include media-breakpoint-only(xs) {
      .btn-default {
        width: 100% !important;
      }
    }
  }

  &__contact {
    margin-top: 60px;

    @include media-breakpoint-only(sm) {
      margin-top: 80px;
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-only(xs) {
      margin-top: 40px;
    }
  }
}
